import React from "react"
import { graphql } from "gatsby"

import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const { t } = useTranslation()

  return (
    <Layout location={location} title={"asdasd"}>
      <Seo title="say what" />
      <h1>
        <Trans>Welcome to my Gatsby site!</Trans>
      </h1>
      <h1>
        <Trans>welcome</Trans>
      </h1>
      <h1>{t("nested.one")}</h1>
      <p>
        <Trans>My name is Shanika</Trans>
      </p>
      <p>
        <Trans>My profession is SSE</Trans>
      </p>
      <p>
        <Trans>My Birthday is 1990/10/10</Trans>
      </p>
      <p>{t("message")}</p>
    </Layout>
  )
}

export default BlogIndex

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
